<template>
  <div v-editable="blok" class="mx-auto flex w-full max-w-4xl flex-col gap-12">
    <div class="relative flex">
      <div class="bgify-text inline-images prose prose-xl text-3xl dark:prose-invert" v-html="text" />
      <div class="z-10 -mr-2 -mt-2 hidden min-w-32 rotate-[8deg] tablet:block">
        <img
          width="143"
          height="200"
          src="https://a.storyblok.com/f/260756/377x526/56f5852da9/3geheimniss_pdf.jpg/m/fit-in/200x200/filters:format(webp,png)"
          class="shadow-sm -ml-2 block aspect-[143/200] overflow-clip rounded border-2 border-text bg-text shadow-blue-dark"
          loading="lazy"
          alt=""
        />
      </div>
    </div>

    <form
      ref="formEl"
      :action="actionUrl"
      method="post"
      class="flex flex-col gap-4 desktop:flex-row"
      aria-haspopup="dialog"
      aria-controls="newsletter-success"
      aria-label="Jetzt unsere kostenfreie PDF sichern"
      @submit.prevent="formSubmitted()"
      @reset="state = { loading: false, error: false, success: false }"
    >
      <div class="flex flex-grow flex-col gap-4 transition-opacity" :class="{ 'pointer-events-none opacity-50': state.success }">
        <input
          type="email"
          inputmode="email"
          name="email"
          aria-label="E-Mail-Adresse"
          class="h-[3.75rem] !text-xl placeholder:text-text"
          placeholder="Tippen, um Ihre E-Mail-Adresse einzutragen"
          autocomplete="email"
          required
          :disabled="state.success"
        />
        <div class="flex flex-row items-start">
          <input id="gdpr-newsletter" type="checkbox" name="gdpr" required :disabled="state.success" />
          <label for="gdpr-newsletter" class="prose prose-base leading-snug dark:prose-invert" v-html="gdprText" />
        </div>
      </div>
      <div class="transition-opacity" :class="{ 'pointer-events-none opacity-50': state.success }">
        <audoora-button
          type="submit"
          class="button small w-full desktop:min-w-[14rem]"
          :aria-disabled="state.success"
          :tabindex="state.success ? -1 : 0"
          :loading="state.loading"
          :error="state.error"
          :success="state.success"
        >
          {{ blok.buttonText || "Jetzt sichern" }}<nuxt-icon name="chevron-right" class="ml-8 [&>svg]:!mb-0" filled />
        </audoora-button>
      </div>

      <div
        v-if="blok.successText || blok.successButtonText"
        class="absolute left-1/2 top-0 z-10 h-full w-dvw -translate-x-1/2 bg-blue/30 transition-opacity"
        :class="{ 'pointer-events-none opacity-0': !state.success }"
        :aria-hidden="!state.success"
      >
        <div
          id="newsletter-success"
          class="prose prose-lg absolute left-1/2 top-1/2 flex w-full max-w-[min(100%,25rem)] -translate-x-1/2 -translate-y-1/2 flex-col rounded border-2 border-primary bg-back-accent p-4 text-center leading-snug"
          :aria-hidden="!state.success"
          role="alert"
          aria-live="assertive"
        >
          <p v-if="blok.successText" class="prose prose-lg whitespace-pre-wrap dark:prose-invert">
            {{ blok.successText }}
          </p>
          <button
            v-if="blok.successButtonText"
            type="reset"
            class="button small mt-4"
            :disabled="!state.success"
            :tabindex="!state.success ? -1 : 0"
          >
            {{ blok.successButtonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import type { CtaNewsletterStoryblok } from "~/storyblok-component-types"

const props = defineProps<{ blok: CtaNewsletterStoryblok; }>()

const actionUrl = ref("/api/form-submit/ha-secrets")
const gdprText
  = 'Ich stimme zu, dass audoora mich zum Thema Hörgesundheit kontaktieren darf. Diese Einwilligung kann ich jederzeit widerrufen. Weitere Informationen finden Sie in unserer <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>.'
const formSubmitCookie = useCookie<boolean>(`${actionUrl.value}-submitted`, { maxAge: 60 * 60 * 24 * 30, sameSite: true })
const formEl = ref<HTMLFormElement>()

const text = computed(() => renderRTF(props.blok.text).value?.replaceAll("<b><span", "<span><b").replaceAll("</b></span", "</span></b"))

const state = ref({
  loading: false,
  error: false,
  success: false,
})

watch(
  () => state.value.success,
  newVal => {
    if (newVal) withConsent(consent => consent.categories.functionality && (formSubmitCookie.value = true))
  },
)

async function formSubmitted() {
  try {
    state.value.loading = true

    await $fetch(actionUrl.value, {
      method: "POST",
      body: {
        pageName: useRoute().name,
        gdprText,
        fields: Object.fromEntries(new FormData(formEl.value)),
        redirect: `/${((props.blok.successPage?.story as { url: string; })?.url || props.blok.successPage?.url || props.blok.successPage?.cached_url) ?? ""}`,
      },
    })
      .then(() => {
        const successUrl
          = (props.blok.successPage?.story as { url: string; })?.url || props.blok.successPage?.url || props.blok.successPage?.cached_url
        if (successUrl) useRouter().push(`/${successUrl}`)
      })
      .catch(() => {
        throw new Error("Form submit failed")
      })

    state.value.success = true

    metaTrack("track", "CompleteRegistration", {
      content_name: "form-three-secrets",
    })
    googleTagTrack("event", "subscribe_secrets")
  } catch (error) {
    state.value.error = true
  } finally {
    state.value.loading = false
  }
}
</script>

<style lang="scss">
.bgify-text {
  padding: 0.5rem;

  p span {
    display: inline;
    line-height: 3rem;
    background-color: theme("colors.back.accent") !important;
    border-radius: 0.125rem;
    box-shadow: 0 0 0 8px theme("colors.back.accent");
    box-decoration-break: clone;
  }

  p span [style*="background-color"] {
    background-color: transparent !important;
  }
}
</style>
